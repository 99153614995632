import React, { useState, useEffect } from "react";
import { Flex, Text, Input } from "@chakra-ui/react";
import DynamicInput from "./DynamicInput"; // Adjust the import based on your setup
import { toast } from "react-toastify";
// import { ToastContainer, toast } from "react-toastify";

const OptionsWithCheckboxes = React.memo(
  ({
    urlOptions,
    colorMain,
    setSelectionDetails,
    amount,
    setIsSelectButtonDisabled,
    setTotalSplitAmountB,
    totalSplitAmountB,
    setAmount,
    setAnyChecked,
    isCheckedAanyChecked,
  }) => {
    const [selectedOptions, setSelectedOptions] = useState(
      urlOptions.map((option) => {
        const predefinedAmount = option?.target || 0;
        return {
          name: option.value,
          isChecked: false,
          amount: predefinedAmount > 0 ? predefinedAmount : "",
          isEditable: predefinedAmount < 1, 
        };
      })
    );
    const [remainingAmount, setRemainingAmount] = useState(amount);
    const [toastId, setToastId] = useState(null);

    // const toast = useToast();

    const handleCheckboxChange = (index) => {
      setSelectedOptions((prevOptions) => {
        const updatedOptions = [...prevOptions];
        const isChecked = !updatedOptions[index].isChecked;
        const predefinedAmount =
          urlOptions.find(
            (option) => option.value === updatedOptions[index].name
          )?.target || 0;
        updatedOptions[index] = {
          ...updatedOptions[index],
          isChecked,
          amount: isChecked
            ? predefinedAmount > 0
              ? predefinedAmount
              : remainingAmount > 0
              ? remainingAmount
              : ""
            : predefinedAmount > 0
            ? predefinedAmount
            : "",
          isEditable: predefinedAmount < 1,
        };

        // Recalculate total amount and remaining amount
        const totalAmount = updatedOptions
          .filter((option) => option.isChecked)
          .reduce((sum, option) => {
            return sum + (parseFloat(option.amount) || 0);
          }, 0);

        const checkedTotalAmount = updatedOptions
          .filter((option) => option.isChecked)
          .reduce((sum, option) => sum + parseFloat(option.amount) || 0, 0);

        // Determine if there are any editable options and non-editable options
        const hasEditableOptions = selectedOptions.some(
          (option) => option.isEditable
        );
        const hasNonEditableOptions = selectedOptions.some(
          (option) => !option.isEditable || !option.amount
        );
        if (hasEditableOptions && hasNonEditableOptions) {
          // console.log(
          //   predefinedAmount,
          //   remainingAmount,
          //   checkedTotalAmount,
          //   totalAmount,
          //   amount
          // );
          setTotalSplitAmountB(totalAmount);
          setAmount(totalAmount);
        }
        if (predefinedAmount && predefinedAmount > 0) {
          console.log(checkedTotalAmount, totalAmount);
          setAmount(checkedTotalAmount);
        }

        setTotalSplitAmountB(totalAmount);
        setRemainingAmount(amount - totalAmount);
        if (predefinedAmount && predefinedAmount > 0) {
          // console.log(
          //   predefinedAmount,
          //   remainingAmount,
          //   checkedTotalAmount,
          //   totalAmount,
          //   amount
          // );
          setRemainingAmount(
            parseFloat(checkedTotalAmount, 0) - parseFloat(predefinedAmount, 0)
          );
        }

        return updatedOptions;
      });
    };

    const handleInputChange = (e, index, type) => {
      let { value } = e.target;

      const intValue = parseInt(value, 10) || 0;

      setSelectedOptions((prevOptions) => {
        const updatedOptions = [...prevOptions];
        const option = updatedOptions[index];
        const predefinedAmount =
          urlOptions.find((o) => o.value === option.name)?.target || 0;
        // console.log(option.isEditable);
        // Only update amount if the option is editable
        updatedOptions[index] = {
          ...option,
          [type]: option.isEditable ? intValue : predefinedAmount,
        };

        // Calculate the total amount
        const totalAmount = updatedOptions
          .filter((option) => option.isChecked)
          .reduce((sum, option) => {
            return sum + (parseFloat(option.amount) || 0);
          }, 0);

        const checkedTotalAmount = updatedOptions
          .filter((option) => option.isChecked)
          .reduce((sum, option) => sum + parseFloat(option.amount) || 0, 0);

        // Determine if there are any editable options and non-editable options
        const hasEditableOptions = selectedOptions.some(
          (option) => option.isEditable
        );
        const hasNonEditableOptions = selectedOptions.some(
          (option) => !option.isEditable
        );
        if (
          totalAmount <= amount &&
          !(hasEditableOptions && hasNonEditableOptions)
        ) {
          // console.log("here is total amount");
          setTotalSplitAmountB(totalAmount);
          setRemainingAmount(amount - totalAmount);
          if (predefinedAmount && predefinedAmount > 0) {
            setRemainingAmount(
              parseFloat(amount, 0) - parseFloat(checkedTotalAmount, 0)
            );
          }
          return updatedOptions;
        } else if (hasEditableOptions && hasNonEditableOptions) {
          console.log(
            predefinedAmount,
            remainingAmount,
            checkedTotalAmount,
            totalAmount,
            amount
          );
          setTotalSplitAmountB(totalAmount);
          setAmount(checkedTotalAmount);
          setRemainingAmount(amount - totalAmount);
          return updatedOptions;
        } else {
          const newToastId = toast.warning(
            amount < 1
              ? "Please fill in the amount at the top!"
              : "Total split amount must not exceed the amount threshold!",
            {
              toastId: "warning-toast",
              autoClose: 3000,
              isClosable: true,
            }
          );
          setToastId(newToastId);
          return prevOptions;
        }
      });
    };

    useEffect(() => {
      const allCheckedHaveAmount = selectedOptions
        .filter((option) => option.isChecked)
        .every((option) => {
          const amountStr = String(option.amount).trim();
          return amountStr !== "";
        });

      const checkedOptions = selectedOptions.filter(
        (option) => option.isChecked
      );
      const totalCheckedAmount = checkedOptions.reduce(
        (sum, option) => sum + parseFloat(option.amount || 0),
        0
      );

      const anyChecked = selectedOptions.some((option) => option.isChecked);

      if (allCheckedHaveAmount) {
        setSelectionDetails(checkedOptions);
      } else {
        setSelectionDetails([]);
      }
      setIsSelectButtonDisabled(
        !(
          totalSplitAmountB === parseFloat(amount) &&
          anyChecked &&
          allCheckedHaveAmount
        )
      );

      const hasEditableOptions = selectedOptions.some(
        (option) => option.isEditable
      );
      const hasNonEditableOptions = selectedOptions.some(
        (option) => !option.isEditable
      );

      if (hasEditableOptions && hasNonEditableOptions) {
        setIsSelectButtonDisabled(!allCheckedHaveAmount);
      }
      setAnyChecked(anyChecked);

      // setRemainingAmount(
      //   parseFloat(amount) - parseFloat(totalSplitAmountB) || 0
      // );

      // Update remaining amount based on the total amount and predefined amounts
      let remaining = parseFloat(amount) - totalCheckedAmount;

      // Adjust remaining amount if any of the checked options have predefined amounts
      checkedOptions.forEach((option) => {
        const predefinedAmount =
          urlOptions.find((o) => o.value === option.name)?.target || 0;

        if (predefinedAmount > 0) {
          remaining += predefinedAmount - parseFloat(option.amount || 0);
        }
      });

      setRemainingAmount(remaining > 0 ? remaining : 0);
    }, [
      urlOptions,
      selectedOptions,
      totalSplitAmountB,
      amount,
      setSelectionDetails,
      setIsSelectButtonDisabled,
      setAnyChecked,
    ]);

    useEffect(() => {
      const updatedOptions = selectedOptions.map((option) => ({
        ...option,
        amount: option.amount.toString().replace(/^0+(?!\.)/, ""),
      }));

      // Update state only if there is a change
      setSelectedOptions((prevOptions) => {
        if (JSON.stringify(prevOptions) !== JSON.stringify(updatedOptions)) {
          return updatedOptions;
        }
        return prevOptions;
      });
    }, [selectedOptions]);

    return (
      <div>
        {isCheckedAanyChecked && remainingAmount !== 0 && (
          <div>
            <span
              className="helper-text"
              style={{
                fontSize: "12px",
                fontFamily: "poppins",
                fontWeight: "semibold",
                color: remainingAmount < 0 ? "red" : `orange`,
              }}
            >
              {remainingAmount < 0
                ? `Total allocation exceeds the limit by: KES ${Math.abs(
                    remainingAmount
                  ).toLocaleString()}`
                : `Amount left to allocate: KES ${remainingAmount.toLocaleString()}`}
            </span>
          </div>
        )}

        <Flex shadow="base" bg="#f4f4f2" flexDir="column" px={1} py={5} mt={3}>
          {selectedOptions.map((option, index) => (
            <Flex
              key={index}
              w="100%"
              alignItems="center"
              justifyContent="space-between"
              mb={4}
            >
              <Flex
                key={index}
                w="100%"
                alignItems="center"
                justifyContent="space-between"
                mb={4}
              >
                <Flex w="65%" alignItems="column" display="flex">
                  <input
                    type="checkbox"
                    checked={option.isChecked}
                    onChange={() => handleCheckboxChange(index)}
                    style={{
                      accentColor: colorMain,
                      cursor: "pointer",
                      marginRight: "6px",
                      width: "20px",
                    }}
                  />{" "}
                  <Input
                    borderRadius="3px"
                    border="1px solid #eeeeee"
                    mb={"0px"}
                    placeholder="0.00"
                    fontSize="2xl"
                    opacity="1 !important"
                    // size="lg"
                    autoComplete="off"
                    value={option.name}
                    onChange={() => {}} // Change to an empty function if not used
                    isReadOnly
                    style={{
                      width: "100%",
                      overflowX: "auto", // Enable horizontal scrolling
                      whiteSpace: "nowrap", // Prevent text wrapping
                      WebkitOverflowScrolling: "touch", // Smooth scrolling on iOS devices
                    }}
                    _hover={{
                      outline: "none",
                    }}
                    _active={{
                      bg: "white",
                      color: "#2fb5b0",
                      outline: "none",
                    }}
                    borderColor={option?.isChecked ? "black" : "transparent"}
                    backgroundColor={
                      option?.isChecked ? "white" : "transparent"
                    }
                  />
                </Flex>
                {option.isChecked && <Text fontWeight="bold">-</Text>}
                {option.isChecked && (
                  <Flex flexDir="column" w="25%">
                    <DynamicInput
                      type="number"
                      min="1"
                      mb={0}
                      value={option.amount}
                      onChange={(e) => handleInputChange(e, index, "amount")}
                      placeholder="Amount"
                    />
                    {!option.amount && option.isChecked && (
                      <span className="helper-text">Amount is required</span>
                    )}
                  </Flex>
                )}
              </Flex>
              {/* <Flex
            alignItems="center"
            w="100%"
            gap={5}
            // justifyContent="space-between"
          >
            <Text
              display="flex"
              ml={2}
              // fontSize="md"
              overflowWrap="break-word"
              maxWidth="60%"
              whiteSpace="normal"
              wordBreak="break-word"
            >
              <input
                type="checkbox"
                checked={option.isChecked}
                onChange={() => handleCheckboxChange(index)}
                style={{
                  accentColor: colorMain,
                  cursor: "pointer",
                  marginRight: "8px",
                }}
              />{" "}
              {option.name}
            </Text>
            {option.isChecked && <Text fontWeight="bold">-</Text>}
            {option.isChecked && (
              <Flex
                ml={4}
                flexDir="column"
                w="25%"
                mt={!option.amount ? 3 : "0"}
                mb={2}
              >
                <DynamicInput
                  type="number"
                  min="0"
                  mb={0}
                  value={option.amount}
                  onChange={(e) => handleAmountChange(e, index)}
                  placeholder="Amount"
                />
                {!option.amount && option.isChecked && (
                  <span className="helper-text">Amount is required</span>
                )}
              </Flex>
            )}
          </Flex> */}
            </Flex>
          ))}
        </Flex>
        {/* <Flex alignItems="center" justifyContent="center" w="100%">
        <Button
          border="1px solid black"
          borderColor={colorMain}
          alignItems="center"
          justifyContent="center"
          gap={2}
          fontSize="xl"
          fontWeight="bold"
          borderRadius="10px"
          w="fit-content"
          background="transparent"
          onClick={handleAddButtonClick}
          isDisabled={buttonDisabled}
        >
          <Icon as={HiPlusCircle} />
          Add Selected Options
        </Button>
      </Flex> */}
      </div>
    );
  }
);

export default OptionsWithCheckboxes;
