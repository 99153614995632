import React, { useEffect, useRef, useState } from "react";
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Text,
  Flex,
  FormLabel,
  Input,
  FormControl,
  Button,
  Textarea,
  RadioGroup,
  Radio,
  Stack,
  Checkbox,
  Box,
} from "@chakra-ui/react";
import { ToastContainer, toast } from "react-toastify";
import FadeIn from "react-fade-in/lib/FadeIn";
import { checkboxAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { getSurveyQuestions, handleSurveySubmit } from "../../utils/api";
import { handleInputSurvey } from "../../utils/functions";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const baseStyle = definePartsStyle({
  control: {
    padding: 30,
    borderRadius: 0,
  },
});

export const checkboxTheme = defineMultiStyleConfig({ baseStyle });

const SurveyDrawer = React.memo(
  ({
    placement = "bottom",
    onClose,
    isCentered,
    subActive,
    payLink,
    data,
    colorMain,
    number,
    phoneLength,
    name,
    setName,
    disabled,
    trigger,
    setPhoneLength,
    setNumber,
    survey,
    contactId,
  }) => {
    const [isChecked, setIsChecked] = useState(false);
    const [triggerSurvey, setTriggerSurvey] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [answers, setAnswers] = useState({});

    let group_id = data?.data?.saledata?.sale_to || "";
    useEffect(() => {
      if (group_id) {
        getSurveyQuestions({
          group_id,
          setSurveyQuestions: setQuestions,
        });
      }
    }, [group_id]);

    // Handle answer change for different question types
    const handleAnswerChange = (questionIndex, answer) => {
      setAnswers((prevAnswers) => {
        if (prevAnswers[questionIndex] === answer) {
          return prevAnswers; // No state update needed
        }
        return {
          ...prevAnswers,
          [questionIndex]: answer,
        };
      });
    };

    // Handle checkbox change
    const handleCheckboxChange = (questionId, option) => {
      setAnswers((prevAnswers) => {
        const currentAnswers = prevAnswers[questionId] || [];
        if (currentAnswers.includes(option)) {
          // Uncheck the checkbox (remove option)
          return {
            ...prevAnswers,
            [questionId]: currentAnswers.filter((item) => item !== option),
          };
        } else {
          // Check the checkbox (add option)
          return {
            ...prevAnswers,
            [questionId]: [...currentAnswers, option],
          };
        }
      });
    };

    let contact_exists = contactId?.length > 0 ? true : false;

    // Handle form submission
    const handleSubmit = () => {
      const response_data = Object.keys(answers).map((questionIndex) => ({
        question_index: questionIndex,
        response: Array.isArray(answers[questionIndex])
          ? answers[questionIndex].join(", ") // Join multiple checkbox answers into a single string
          : answers[questionIndex], // Single response for text or radio buttons
      }));

      handleSurveySubmit({
        group_id,
        survey_id: questions?.data?.id,
        contact_exists,
        contact_id: contact_exists ? contactId : data?.data?.saledata?.org_id,
        contact_name: `${name}, ${number}`,
        response_data,
        toast,
        setTriggerSurvey,
      });
      onClose();
    };

    const capitalizeFirstLetter = (str) => {
      return str.replace(/\b\w/g, (char) => char.toUpperCase());
    };

    return (
      questions &&
      questions?.data?.survey_questions && (
        <Drawer
          placement={placement}
          isOpen={survey}
          onClose={onClose}
          isCentered={isCentered}
          p="2px"
          display="flex"
          alignItems="center"
          flexDir="column"
          closeOnOverlayClick={trigger ? false : true}
        >
          <ToastContainer />
          <DrawerOverlay />
          {payLink === undefined || !subActive ? (
            <DrawerContent mb="300">
              <DrawerCloseButton />
              <DrawerBody p={9} fontSize="2xl">
                <Text>
                  Unfortunately, this service is temporarily unavailable as the
                  organizer's plan needs to be renewed. Please check back later
                  or contact the organizer for further assistance. We sincerely
                  apologize for the inconvenience.
                </Text>
              </DrawerBody>
            </DrawerContent>
          ) : (
            <>
              <DrawerOverlay />
              <DrawerContent borderTopRadius="20px">
                <DrawerCloseButton />
                <DrawerHeader
                  mt={3}
                  textAlign="center"
                  mb={0}
                  color={colorMain}
                >
                  Please answer the questions below
                </DrawerHeader>
                <DrawerBody py={0} color={colorMain}>
                  <FadeIn>
                    <Flex
                      w="100%"
                      alignItems="center"
                      justifyContent="space-between"
                      flexDir="column"
                    >
                      <FormControl isRequired fontSize="2xl" mt={5} mb={8}>
                        <FormLabel fontSize="xl" fontWeight="semibold">
                          Phone number
                        </FormLabel>
                        <Input
                          fontSize={["xl"]}
                          mb={1}
                          type="tel"
                          placeholder={!number ? "Enter number here" : ""}
                          value={number}
                          onChange={(e) =>
                            handleInputSurvey(e, setPhoneLength, setNumber)
                          }
                        />
                      </FormControl>
                    </Flex>

                    {number?.length === phoneLength && (
                      <FadeIn>
                        <FadeIn>
                          <FormLabel fontSize="xl" fontWeight="semibold">
                            {data?.data.saledata?.sale_settings?.name_label ||
                              "Name"}
                          </FormLabel>
                          <Flex
                            w="100%"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Input
                              fontSize={["xl"]}
                              type="text"
                              placeholder={!name ? "Enter details here" : ""}
                              value={isChecked ? "Anonymous" : name}
                              onChange={(e) => setName(e.target.value)}
                              isDisabled={disabled}
                            />
                          </Flex>
                        </FadeIn>
                        <Checkbox
                          mt={2}
                          mb={8}
                          size="xl"
                          colorScheme="teal"
                          isChecked={isChecked}
                          onChange={() => setIsChecked(!isChecked)}
                        >
                          Fill anonymously
                        </Checkbox>
                      </FadeIn>
                    )}

                    {questions?.data?.survey_questions?.map((question) => (
                      <SurveyQuestion
                        key={question.question_index}
                        question={question}
                        handleAnswerChange={handleAnswerChange}
                        handleCheckboxChange={handleCheckboxChange}
                        answers={answers}
                        capitalizeFirstLetter={capitalizeFirstLetter}
                      />
                    ))}
                  </FadeIn>
                </DrawerBody>

                <DrawerFooter
                  align="center"
                  justify="center"
                  display="flex"
                  flexDir="column"
                >
                  <Button
                    onClick={handleSubmit}
                    bgColor={colorMain}
                    isDisabled={
                      number?.length !== phoneLength ||
                      !name.trim() ||
                      !questions?.data?.survey_questions.some(
                        (question) => answers[question.question_index]
                      )
                    }
                    color="white"
                    align="center"
                    p="18px"
                    borderRadius="3px"
                    fontWeight="bold"
                    mb={6}
                    w="100%"
                    px={16}
                    fontSize="2xl"
                    isLoading={triggerSurvey}
                  >
                    Submit
                  </Button>
                </DrawerFooter>
              </DrawerContent>
            </>
          )}
        </Drawer>
      )
    );
  }
);

export default SurveyDrawer;

const SurveyQuestion = ({
  question,
  handleAnswerChange,
  handleCheckboxChange,
  answers,
  capitalizeFirstLetter,
}) => {
  const options = question.question_options
    .split(/,\s*/i)
    .map((option) => option.toLowerCase());

  const isBooleanQuestion =
    (options.length === 2 &&
      options.includes("yes") &&
      options.includes("no")) ||
    (options.length === 2 &&
      options.includes("one") &&
      options.includes("two")) ||
    (options.length === 2 &&
      options.includes("true") &&
      options.includes("false")) ||
    (options.length === 3 &&
      options.includes("yes") &&
      options.includes("no") &&
      options.includes("not sure")) ||
    (options.length === 3 &&
      options.includes("true") &&
      options.includes("false") &&
      options.includes("not sure"));

  switch (question.question_type) {
    case "text":
      return (
        <Box key={12} mb={8}>
          <FormLabel fontSize={["xl"]} fontWeight="semibold">
            {question.question}
          </FormLabel>
          <Textarea
            type="text"
            fontSize={["xl"]}
            placeholder="Type your answer here..."
            value={answers[question.question_index] || ""}
            onChange={(e) =>
              handleAnswerChange(question.question_index, e.target.value)
            }
          />
        </Box>
      );
    case "select":
      return (
        <Box key={question.question_index} mb={8}>
          <FormLabel fontSize={["xl"]} fontWeight="semibold">
            {question.question}
          </FormLabel>
          <RadioGroup
            fontSize={["xl"]}
            value={answers[question.question_index] || ""}
            onChange={(value) =>
              handleAnswerChange(question.question_index, value)
            }
          >
            <Stack direction="column" fontSize={["xl"]}>
              {options.map((option, idx) => (
                <Radio
                  key={idx}
                  value={option}
                  colorScheme="teal"
                  size="lg"
                  fontSize="xl"
                >
                  <span style={{ fontSize: "14px" }}>
                    {capitalizeFirstLetter(option)}
                  </span>
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
        </Box>
      );
    case "options":
      if (isBooleanQuestion) {
        return (
          <Box key={question.question_index} mb={8}>
            <FormLabel fontSize={["xl"]} fontWeight="semibold">
              {question.question}
            </FormLabel>
            <RadioGroup
              fontSize={["xl"]}
              value={answers[question.question_index] || ""}
              onChange={(value) =>
                handleAnswerChange(question.question_index, value)
              }
            >
              <Stack direction="column" fontSize={["xl"]}>
                {options.map((option, idx) => (
                  <Radio
                    key={idx}
                    value={option}
                    colorScheme="teal"
                    size="lg"
                    fontSize="xl"
                  >
                    <span style={{ fontSize: "14px" }}>
                      {capitalizeFirstLetter(option)}
                    </span>
                  </Radio>
                ))}
              </Stack>
            </RadioGroup>
          </Box>
        );
      } else {
        // Handle other single-choice or multiple-choice options
        return (
          <Box key={question.id} mb={8}>
            <FormLabel fontSize={["xl"]} fontWeight="semibold">
              {question.question}
            </FormLabel>
            {options.length > 1 ? (
              <Stack direction="column">
                {options.map((option, idx) => (
                  <Checkbox
                    key={idx}
                    value={option}
                    colorScheme="teal"
                    size="xl"
                    fontSize="xl"
                    isChecked={
                      answers[question.question_index]?.includes(option) ||
                      false
                    }
                    onChange={() =>
                      handleCheckboxChange(question.question_index, option)
                    }
                  >
                    <span>{capitalizeFirstLetter(option)}</span>
                  </Checkbox>
                ))}
              </Stack>
            ) : (
              <RadioGroup
                fontSize={["xl"]}
                value={answers[question.question_index] || ""}
                onChange={(value) =>
                  handleAnswerChange(question.question_index, value)
                }
              >
                <Stack direction="column">
                  {options.map((option, idx) => (
                    <Radio
                      key={idx}
                      value={option}
                      colorScheme="teal"
                      size="xl"
                    >
                      <span style={{ fontSize: "14px" }}>
                        {capitalizeFirstLetter(option)}
                      </span>
                    </Radio>
                  ))}
                </Stack>
              </RadioGroup>
            )}
          </Box>
        );
      }
    default:
      return null;
  }
};
