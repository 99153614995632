import {
  Button,
  Flex,
  Icon,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import ShareModal from "./Features/ShareModal";
import { colorMain } from "../constants/themes";
import ProgressBar from "@ramonak/react-progress-bar";
import { FaShareAlt } from "react-icons/fa";
import DynamicButton from "./Features/DynamicButton";
import { shareUrl } from "../constants/variables";
import Footer from "./Features/Footer";
import DynamicDrawer from "./Features/DynamicDrawer";
import ConfirmModal from "./Features/ConfirmModal";
import Truncate from "./Features/Truncate";
import React, { useEffect, useState } from "react";
import ListsModal from "./Features/ListsModal";
import { renderMessageWithLinksDesc } from "../utils/functions";
import collectLogo from "../assets/img/nobukcollect.png";
import Approval from "../assets/img/Approval.svg";
import callendar from "../assets/img/callendar.svg";
import location from "../assets/img/location.svg";
import Moment from "react-moment";
import EventsTemplate from "./collectTemplates/EventsTemplates";
import SurveyDrawer from "./Features/SurveyDrawer";

const CollectPage = React.memo(
  ({
    dataSale,
    jpg,
    setIsShare,
    isShare,
    data,
    type,
    mpesa,
    number,
    handleInput,
    nameOption,
    phoneLength,
    disabled,
    setName,
    setRecurring,
    recurring,
    setSelection,
    amount,
    opening,
    subActive,
    payLink,
    customerName,
    buttonDisable,
    buttone,
    selection,
    setPledgeAmount,
    pledgeAmount,
    handlePledgeContribution,
    pledgeNumber,
    isToOpen,
    handleCloseThisModal,
    updating,
    paymentStatus,
    paidBy,
    phoneNumberBy,
    transactionCode,
    handleCardClick,
    handlePaymentRecheck,
    handleRepay,
    onOpen,
    trigger,
    name,
    isOpen,
    onClose,
    setAmount,
    handlePayment,
    setNumber,
    setPhoneLength,
    setPledgeNumber,
    setOpening,
    handleInputPledge,
    setCustomerName,
    setIsToOpen,
    urlOption,
    urlAmount,
    urlPhone,
    urlOptions,
    setPayType,
    payType,
    checkoutUrl,
    setCheckoutUrl,
    setVerify,
    verify,
    paymentData,
    trackCode,
    downloadPdf,
    toast,
    setOtherDetails,
    setSelectionDetails,
    saleId,
    defaultOptions,
    memberPay,
    subscribeData,
    sales_code,
    org_id,
    survey,
    setSurvey,
    contactId,
  }) => {
    const [selectedOption, setSelectedOption] = useState(false);
    const [enteredAmount, setEnteredAmount] = useState(false);
    const [venue, setVenue] = useState();
    const [detail, setDetail] = useState(
      data?.data?.saledata?.contact_detail || ""
    );

    const current_level =
      data?.data?.saledata?.current_level >= 0 ? true : false;

    const clickComplete = () => {
      handlePaymentRecheck();
      setVerify(false);
      closeCheckOut();
    };

    const closeCheckOut = () => {
      setCheckoutUrl();
    };

    useEffect(() => {
      const regex = /{([^}]+)}/;

      const match = detail?.match(regex);

      if (match) {
        const ven = match[1];
        const updatedContactDetail = detail?.replace(`{${ven}}`, "");
        setDetail(updatedContactDetail);
        setVenue(ven);
        // console.log(detail);
      }
    }, [detail]);
    useEffect(() => {
      const digitRegex = /^\d+$/;

      if (urlOption && urlOptions.includes(urlOption?.trim())) {
        setSelectedOption(true);
      }

      if (urlPhone) {
        setNumber(urlPhone);
        handleInput(urlPhone, setPhoneLength, setNumber);
      }

      if (!urlAmount) {
        if (urlOption && urlOptions.includes(urlOption?.trim())) {
          return;
        } else if (urlOption && digitRegex.test(urlOption)) {
          setEnteredAmount(true);
          setAmount(urlOption);
          // setPledgeAmount(urlOption);
        }
      }

      if (urlAmount > 0) {
        setEnteredAmount(true);
      }
    }, [urlOption, urlPhone, urlAmount, urlOptions]);

    const commonProps = {
      placement: "bottom",
      isCentered: true,
      subActive: subActive,
      payLink: payLink,
      data: data,
      colorMain: colorMain,
      amount: amount,
      setAmount: setAmount,
      mpesa: mpesa,
      number: number,
      phoneLength: phoneLength,
      name: name,
      setName: setName,
      setRecurring: setRecurring,
      recurring: recurring,
      disabled: disabled,
      selection: selection,
      setSelection: setSelection,
      handleInput: handleInput,
      pledgeNumber: pledgeNumber,
      handleInputPledge: handleInputPledge,
      customerName: customerName,
      setCustomerName: setCustomerName,
      pledgeAmount: pledgeAmount,
      setPledgeAmount: setPledgeAmount,
      handlePledgeContribution: handlePledgeContribution,
      buttone: buttone,
      setPhoneLength: setPhoneLength,
      setPledgeNumber: setPledgeNumber,
      buttonDisable: buttonDisable,
      setNumber: setNumber,
      nameOption: nameOption,
      handlePayment: handlePayment,
      trigger: trigger,
      selectedOption: selectedOption,
      enteredAmount: enteredAmount,
      urlOptions: urlOptions,
      setPayType: setPayType,
      payType: payType,
      checkoutUrl: checkoutUrl,
      verify: verify,
      paymentData: paymentData,
      setOtherDetails: setOtherDetails,
      setSelectionDetails: setSelectionDetails,
      venue: venue,
      detail: detail,
      trackCode: trackCode,
      saleId: saleId,
      defaultOptions: defaultOptions,
      memberPay: memberPay,
      subscribeData: subscribeData,
      sales_code: sales_code,
      org_id: org_id,
      survey: survey,
      setSurvey: setSurvey,
      contactId: contactId,
    };

    let template =
      data?.data.saledata?.sale_settings?.template_type || "default";

    let footerData = data?.data?.footer;

    return (
      <Flex
        position="relative"
        flexDirection="column"
        bg="white"
        shadow={["", "base"]}
        borderRadius={["", "4px"]}
      >
        <>
          <Modal
            isOpen={checkoutUrl}
            onClose={closeCheckOut}
            closeOnOverlayClick={false}
            isCentered
            motionPreset="slideInBottom"
            size="xl"
            preserveScrollBarGap="false"
          >
            <ModalOverlay backdropBlur={colorMain} />
            <ModalContent p={0}>
              <ModalHeader>Enter card details to pay</ModalHeader>
              <br />
              <ModalCloseButton />
              <ModalBody p={0}>
                <iframe
                  src={checkoutUrl}
                  title="Paystack Checkout"
                  height="500"
                  width="100%"
                  frameBorder="0"
                />
              </ModalBody>

              <ModalFooter gap={4}>
                <DynamicButton
                  onClickHandler={clickComplete}
                  buttonText="Complete"
                  colorMain={colorMain}
                  isDisabled="true"
                />
              </ModalFooter>
            </ModalContent>
          </Modal>
        </>
        <Flex
          alignItems="center"
          flexDir="column"
          justifyContent="space-between"
        >
          <Flex
            flexDir="column"
            borderRadius="2xl"
            // justifyContent="space-between"
            minH={["85vh", "revert"]}
            maxW="100%"
            px={3}
          >
            <Flex
              flexDir="column"
              borderRadius={["", "4px"]}
              boxShadow="none"
              shadow="none"
            >
              <Flex
                // position="absolute"
                w="100%"
                p={1}
                alignItems="center"
                justifyContent="space-between"
                boxShadow="none"
                shadow="none"
              >
                <Flex>
                  <Image
                    src={collectLogo}
                    objectFit="contain"
                    aspectRatio="16/9"
                    w="80px"
                    opacity="1"
                    alt="Nobuk"
                  />
                </Flex>
                <Button
                  width="fit-content"
                  color={colorMain}
                  fontWeight="semibold"
                  backgroundColor="white"
                  variant="outline"
                  borderRadius="16px"
                  fontSize="xl"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  px={6}
                  onClick={() => {
                    shareUrl && setIsShare(true);
                  }}
                >
                  <Icon as={FaShareAlt} color={colorMain} fontSize="xl" />
                </Button>
                <ShareModal
                  isOpen={isShare}
                  onClose={() => setIsShare(false)}
                  shareUrl={shareUrl}
                  data={data}
                />
              </Flex>
            </Flex>
            <Flex px={4}>
              <Flex
                bgColor="blackAlpha.600"
                borderRadius="5%"
                w="100%"
                shadow="none"
                boxShadow="none"
              >
                <Image
                  src={dataSale.sale_settings?.base_image || jpg}
                  objectFit="cover"
                  aspectRatio="16/9"
                  borderRadius="5%"
                  w="100%"
                  opacity=".7"
                  alt="collect with ease"
                  h={["250px", "260px"]}
                />
              </Flex>
            </Flex>
            <Flex
              justifyContent="flex-start"
              width="fit-content"
              position="relative"
            >
              <Text
                fontWeight="bold"
                fontSize="3xl"
                textAlign="left"
                w="100%"
                pt={4}
                px={4}
                color={colorMain}
              >
                {data?.data.saledata.contact_name}
              </Text>

              <Image
                src={Approval}
                objectFit="contain"
                aspectRatio="16/9"
                w="25px"
                opacity="1"
                alt="*"
              />
            </Flex>
            {template === "tickets" ? (
              <EventsTemplate
                data={data}
                type={type}
                trigger={trigger}
                toast={toast}
                trackCode={trackCode}
                number={number}
                commonProps={commonProps}
                opening={opening}
                setOpening={setOpening}
                verify={verify}
                isToOpen={isToOpen}
                setIsToOpen={setIsToOpen}
                handleCloseThisModal={handleCloseThisModal}
                updating={updating}
                paymentStatus={paymentStatus}
                amount={amount}
                paidBy={paidBy}
                phoneNumberBy={phoneNumberBy}
                transactionCode={transactionCode}
                handleCardClick={handleCardClick}
                handlePaymentRecheck={handlePaymentRecheck}
                handleRepay={handleRepay}
                selection={selection}
                name={name}
                payType={payType}
                dataSale={dataSale}
                downloadPdf={downloadPdf}
                callendar={callendar}
                location={location}
                template={template}
                urlOptions={urlOptions}
                setSelection={setSelection}
                selectedOption={selectedOption}
                setAmount={setAmount}
                setOtherDetails={setOtherDetails}
                setSelectionDetails={setSelectionDetails}
                venue={venue}
              />
            ) : (
              <>
                {template !== "realestate" && (
                  <Flex
                    Flex
                    alignItems="center"
                    justifyContent="flex-start"
                    gap={20}
                    mb={6}
                    pr={5}
                  >
                    <Flex height="fit-content" py={0} pt={2}>
                      <Image
                        src={callendar}
                        objectFit="contain"
                        aspectRatio="16/9"
                        w="30px"
                        ml={3}
                        opacity="1"
                        alt=""
                      />
                      <Flex
                        flexDir="column"
                        fontWeight="light"
                        justifyContent="center"
                        p={0}
                        fontSize="xl"
                        gap={0}
                        ml={2}
                        color="gray.600"
                      >
                        <Text color={colorMain} fontWeight="semibold" m={0}>
                          Date
                        </Text>
                        <Moment
                          format="dddd MMM D"
                          date={
                            new Date(
                              (data?.data.saledata.sales_date).toString()
                                .length === 16
                                ? data?.data.saledata.sales_date / 1000
                                : (data?.data.saledata.sales_date).toString()
                                    .length === 19
                                ? data?.data.saledata.sales_date / 1000000
                                : (data?.data.saledata.sales_date).toString()
                                    .length > 10
                                ? data?.data.saledata.sales_date
                                : data?.data.saledata.sales_date * 1000
                            )
                          }
                        />
                      </Flex>
                    </Flex>
                    {venue && (
                      <Flex
                        flexDir="column"
                        fontWeight="light"
                        justifyContent="center"
                        p={0}
                        fontSize="xl"
                        gap={0}
                        color="gray.600"
                      >
                        <Flex height="fit-content" py={0} pt={2}>
                          <Image
                            src={location}
                            objectFit="contain"
                            aspectRatio="16/9"
                            w="30px"
                            opacity="1"
                            alt=""
                          />
                          <Flex
                            flexDir="column"
                            justifyContent="center"
                            p={0}
                            gap={0}
                            ml={2}
                            color={colorMain}
                          >
                            <Text color={colorMain} fontWeight="semibold" m={0}>
                              Venue
                            </Text>
                            <Text m={0} color="gray.600" fontWeight="light">
                              {venue}
                            </Text>
                          </Flex>
                        </Flex>
                      </Flex>
                    )}
                  </Flex>
                )}
                <Flex
                  flexDir="column"
                  color="gray.600"
                  w="100%"
                  p={4}
                  py={3}
                  pt={4}
                  justifyContent="space-between"
                >
                  {(data?.data.saledata?.sale_settings?.add_about_collection ===
                    null ||
                    data?.data.saledata?.sale_settings?.add_about_collection) &&
                    type !== "Wallet" && (
                      <Flex flexDirection="column">
                        <Text fontWeight="normal">
                          {/\bhttps?:\/\/\S+\b/.test(detail) ||
                          /<[a-z][\s\S]*>/i.test(detail)
                            ? renderMessageWithLinksDesc(detail)
                            : detail !== undefined && (
                                <Truncate text={detail} maxLength={24} />
                              )}
                        </Text>
                      </Flex>
                    )}
                  {type !== "Wallet" && (
                    <>
                      {data?.data.saledata?.sale_settings
                        ?.progress_button_visible && (
                        <>
                          <Flex
                            flexDirection="column"
                            width="100%"
                            className="barss"
                            py={4}
                          >
                            <Flex
                              w="100%"
                              alignItems="center"
                              justifyContent="space-between"
                              pb={1}
                              pt={1}
                            >
                              <Flex alignItems="baseline">
                                <span style={{ fontSize: "1.05rem" }}>
                                  {" "}
                                  Collected:
                                </span>{" "}
                                <Text fontWeight="bold">
                                  <span style={{ fontSize: "1.1rem" }}>
                                    &nbsp;KES&nbsp;
                                  </span>{" "}
                                  {Math.abs(
                                    data?.data.saledata.sale_amount -
                                      data?.data.saledata.sale_balance
                                  ).toLocaleString()}
                                </Text>
                              </Flex>

                              {data?.data?.saledata.sale_amount !== 0 && (
                                <Flex alignItems="baseline">
                                  <span style={{ fontSize: "1.05rem" }}>
                                    {" "}
                                    Target:{" "}
                                  </span>
                                  <Text fontWeight="bold">
                                    <span style={{ fontSize: "1.1rem" }}>
                                      &nbsp;KES&nbsp;
                                    </span>
                                    {(data?.data.saledata.sale_amount).toLocaleString()}
                                  </Text>
                                </Flex>
                              )}
                            </Flex>
                            <ProgressBar
                              animateOnRender="true"
                              completed={
                                data?.data.saledata.sale_amount !== 0
                                  ? Math.ceil(
                                      ((data?.data.saledata.sale_amount -
                                        data?.data.saledata.sale_balance) /
                                        data?.data.saledata.sale_amount) *
                                        100
                                    )
                                  : 100
                              }
                              height="10px"
                              bgColor={colorMain}
                              customLabel="&nbsp;"
                              maxCompleted={100}
                            />
                            <Flex
                              w="100%"
                              alignItems="center"
                              justifyContent="space-between"
                              pb={0}
                              pt={3}
                              m={0}
                            >
                              {current_level && (
                                <Flex alignItems="baseline">
                                  <span style={{ fontSize: "1.05rem" }}>
                                    {" "}
                                    From
                                  </span>

                                  <Text fontWeight="bold">
                                    &nbsp;
                                    {data?.data?.saledata?.current_level?.toLocaleString()}
                                    &nbsp;
                                  </Text>
                                  <span style={{ fontSize: "1.05rem" }}>
                                    {data?.data?.saledata?.current_level !== 1
                                      ? "people"
                                      : "person"}
                                  </span>
                                </Flex>
                              )}
                              <Flex>
                                <Text fontWeight="bold" fontSize="xl">
                                  {data?.data.saledata.sale_amount !== 0
                                    ? Math.ceil(
                                        ((data?.data.saledata.sale_amount -
                                          data?.data.saledata.sale_balance) /
                                          data?.data.saledata.sale_amount) *
                                          100
                                      )
                                    : 100}
                                  %{" "}
                                  <span
                                    style={{
                                      fontSize: "1.05rem",
                                      fontWeight: "lighter",
                                    }}
                                  >
                                    {" "}
                                    Collected
                                  </span>
                                </Text>
                              </Flex>

                              {!current_level && (
                                <Flex>
                                  <Text fontWeight="bold" fontSize="xl">
                                    Collected
                                  </Text>
                                </Flex>
                              )}
                            </Flex>
                          </Flex>
                        </>
                      )}
                    </>
                  )}
                  <Flex>
                    <Flex
                      // align="center"
                      flexDir="column"
                      // justifyContent="center"
                      w="100%"
                      mt={1}
                      mb={0}
                    >
                      {type === "Invoice" ||
                      type === "Sale" ||
                      type === "Collection" ||
                      type === "Wallet" ? (
                        <Flex flexDirection="column" p={2}>
                          {data?.data.saledata?.sale_settings
                            ?.contribute_button_visible && (
                            <DynamicButton
                              onClickHandler={onOpen}
                              buttonText={
                                data?.data.saledata?.sale_settings
                                  ?.contribute_button_name || "click to pay"
                              }
                              isLoading={trigger}
                              colorMain={colorMain}
                            />
                          )}

                          {data?.data.saledata?.sale_settings
                            ?.pledge_button_visible && (
                            <DynamicButton
                              onClickHandler={() => setOpening(true)}
                              buttonText={
                                data?.data.saledata?.sale_settings
                                  ?.pledge_button_name || "Make a Pledge"
                              }
                              isLoading={trigger}
                              colorMain={colorMain}
                            />
                          )}

                          {data?.data.survey && (
                            <DynamicButton
                              onClickHandler={() => setSurvey(true)}
                              buttonText={
                                data?.data?.survey_name || "Take Survey"
                              }
                              isLoading={trigger}
                              colorMain={colorMain}
                            />
                          )}

                          {data?.data.saledata?.sale_settings
                            ?.contribution_list_public && (
                            <Flex
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <ListsModal
                                trackCode={trackCode}
                                trigger={trigger}
                                templateType={
                                  data?.data.saledata?.sale_settings
                                    ?.template_type || "default"
                                }
                              />
                            </Flex>
                          )}
                        </Flex>
                      ) : (
                        <></>
                      )}

                      <DynamicDrawer
                        isOpen={isOpen}
                        onClose={onClose}
                        contributionMode="pay"
                        buttonText={
                          data?.data.saledata?.sale_settings
                            ?.contribute_button_name || "click to pay"
                        }
                        {...commonProps}
                      />

                      <SurveyDrawer
                        isOpen={true}
                        onClose={() => {
                          setSurvey(false);
                        }}
                        contributionMode="pay"
                        buttonText={
                          data?.data.saledata?.sale_settings
                            ?.survey_button_name || "Take Survey"
                        }
                        {...commonProps}
                      />

                      <DynamicDrawer
                        isOpen={opening}
                        onClose={() => setOpening(false)}
                        contributionMode="pledge"
                        buttonText={
                          data?.data.saledata?.sale_settings
                            ?.pledge_button_name || "Make a Pledge"
                        }
                        {...commonProps}
                      />
                      {!verify && (
                        <ConfirmModal
                          isToOpen={isToOpen}
                          setIsToOpen={setIsToOpen}
                          handleCloseThisModal={handleCloseThisModal}
                          updating={updating}
                          paymentStatus={paymentStatus}
                          amount={amount}
                          paidBy={paidBy}
                          phoneNumberBy={phoneNumberBy}
                          transactionCode={transactionCode}
                          colorMain={colorMain}
                          handleCardClick={handleCardClick}
                          handlePaymentRecheck={handlePaymentRecheck}
                          handleRepay={handleRepay}
                          selection={selection}
                          enabled={
                            data &&
                            data?.data.saledata?.sale_settings
                              ?.download_receipt_visible
                          }
                          name={name}
                          orgName={
                            data &&
                            (data?.data.saledata.contact_name || "Anonymous")
                          }
                          number={number}
                          customMessage={
                            data &&
                            data?.data.saledata?.sale_settings?.customer_message
                          }
                          trackCode={trackCode}
                          payType={payType}
                          downloadMessage={
                            data &&
                            data?.data.saledata?.sale_settings
                              ?.download_receipt_name
                          }
                          details={dataSale}
                          downloadPdf={downloadPdf}
                          toast={toast}
                          footerData={footerData}
                        />
                      )}
                    </Flex>
                  </Flex>
                  {/* <Flex w="100%" alignItems="center" justifyContent="center">
                    {data?.data.saledata.org_data.name && (
                      <Flex
                        alignItems="center"
                        color="gray.500"
                        letterSpacing={1}
                        fontSize="13"
                        fontWeight="bold"
                      >
                        <Text display="flex" alignItems="center" my={2} mt={1}>
                          &nbsp; Contact:{" "}
                          {data?.data.saledata.org_data.name || "Anonymous"}
                          {`, ${
                            data?.data.saledata.org_data.main_phone_number || ""
                          }`}
                        </Text>
                      </Flex>
                    )}
                  </Flex> */}
                </Flex>
              </>
            )}
          </Flex>
          {type === "Collection" && footerData?.adshow && (
            <Footer footerData={footerData} />
          )}
        </Flex>
      </Flex>
    );
  }
);

export default CollectPage;
