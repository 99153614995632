import { baseUrl } from "./variables";

// URLs
export const salesUrl = `${baseUrl}/business/sales/data`;
export const namesUrl = `${baseUrl}/crm/contacts/qs?`;
export const payUrl = `${baseUrl}/paylink/stktrigger`;
export const payCardUrl = `${baseUrl}/paylink/cardtrigger`;
export const imagesUrl = `${baseUrl}/ids/images`;
export const pledgeUrl = `${baseUrl}/business/payments`;
export const docUrl = `${baseUrl}/docgen/pdf`;
export const statementsUrl = `${baseUrl}/docgen/statement`;
export const getDocSettingsUrl = `${baseUrl}/ids/docs`;
export const confirmPaymentUrl = `${baseUrl}/business/payments/confirm`;
export const contributorsListUrl = `${baseUrl}/business/collection/contributionlist?ref=`;
export const contributorsStatementsUrl = `${baseUrl}/business/collection/statement`;
export const contributorPledgeUrl = `${baseUrl}/business/collection/pledge`;
export const checkSubUrl = `${baseUrl}/business/payments/user/subscribe`;
export const multinamesUrl = `${baseUrl}/business/collection/multinames`;
export const surveysUrl = `${baseUrl}/crm/surveys/questions/`;
export const surveysSubmitUrl = `${baseUrl}/crm/surveys/responses`;