import axios from "axios";
import {
  confirmPaymentUrl,
  docUrl,
  getDocSettingsUrl,
  imagesUrl,
  namesUrl,
  payUrl,
  payCardUrl,
  pledgeUrl,
  salesUrl,
  contributorsListUrl,
  contributorsStatementsUrl,
  statementsUrl,
  contributorPledgeUrl,
  multinamesUrl,
  checkSubUrl,
  surveysUrl,
  surveysSubmitUrl,
} from "../constants/urls";
import { timer } from "../constants/variables";
import { formatPayDate, timeDate } from "./functions";

const getContributorsList = async (code, setList, setLoading) => {
  setLoading(true);
  try {
    await axios.get(`${contributorsListUrl}${code}`).then((response) => {
      if (response?.data?.message === "Contributor list is private") {
        setList(0);
        return;
      }
      setList(response?.data?.data);
    });
  } catch (error) {}
  setLoading(false);
};

const checkPledge = async (saleId, name, setPledge) => {
  // setIsOpenLoading(true);
  let data = {
    sale_id: saleId,
    customer_name: name,
  };
  try {
    await axios.post(contributorPledgeUrl, data).then((response) => {
      // console.log(response);
      if (response?.data.data?.pledge > 0) {
        setPledge(response?.data.data?.pledge);
        return;
      }
      setPledge(0);
    });
  } catch (error) {
    // console.log(error);
  }
  // setIsOpenLoading(false);
};

const checkSub = async (number, setMemberSub, sales_code, setIsSubLoading) => {
  setIsSubLoading(true);
  let data = {
    customer_phone: number,
    payment_code: sales_code,
  };
  try {
    await axios.post(checkSubUrl, data).then((response) => {
      // console.log(response?.data?.data?.paid)
      if (response?.data?.data?.paid) {
        let paid = response?.data?.data?.paid;
        setMemberSub(paid);
        setIsSubLoading(false);
        return true;
      }
      setMemberSub(false);
    });
  } catch (error) {
    console.log(error);
  }
  setIsSubLoading(false);
  return false;
};

const handleAsync = async (
  code,
  setLoad,
  setTrigger,
  setData,
  setAmount,
  setNumber,
  setName,
  setImage,
  setButtone,
  setNameOption,
  setPaymentData,
  setPayLink,
  setSubActive,
  setPhoneLength,
  urlOption,
  urlAmount,
  setSelection,
  setUrlOptions,
  setPledgeAmount,
  setSaleId,
  setDefaultOptions,
  setSubscribeData,
  setMemberPay
) => {
  setLoad(true);
  setTrigger(false);
  try {
    await axios
      .get(`${salesUrl}/${code}`)
      .then((response) => {
        if (response?.data.success) {
          const linkData = response?.data.data?.saledata;
          setData(response?.data);

          if (
            linkData?.sale_type !== "Collection" &&
            linkData?.sale_type !== "Wallet"
          ) {
            const contactDetail = linkData?.contact_detail || "";
            setAmount(linkData?.sale_balance || "");
            setNumber(contactDetail?.replace("+", ""));
            setPhoneLength(contactDetail?.replace("+", "").length || 10);
            setName(linkData?.contact_name || "");
          } else {
            setAmount(urlAmount || "");
            // setPledgeAmount(urlAmount || "");
            setSelection(urlOption || "");
            setNumber("");
            setPhoneLength(10);
            setName("");

            // setUrlOptions(
            //   (linkData?.sale_settings?.pay_detail_value || "")
            //     .split(",")
            //     .map((option) => option.trim())
            //     .filter(Boolean)
            // );

            const payDetailValues = (
              linkData?.sale_settings?.pay_detail_value || ""
            )
              .split(",")
              .map((option) => option.trim())
              .filter(Boolean);

            const payDetailsTargets = JSON.parse(
              linkData?.sale_settings?.pay_details_targets || "{}"
            );

            const targetsLookup = (payDetailsTargets?.targets || []).reduce(
              (acc, target) => {
                acc[target.name] = target.amount;
                return acc;
              },
              {}
            );

            const urlOptions = payDetailValues.map((payDetail) => ({
              value: payDetail,
              target: targetsLookup[payDetail] || 0,
            }));

            setUrlOptions(urlOptions);
          }

          setImage(linkData?.sale_settings?.base_image);
          setButtone(linkData?.sale_settings?.pay_detail_button);
          setNameOption(linkData?.sale_settings?.name_value_required);
          setPaymentData(linkData?.payment_data);
          setPayLink(linkData?.paylink_id);
          // console.log(linkData?.paylink_id)
          setSubActive(response?.data.data.subscription_active);
          setLoad(false);
          setSaleId(linkData?.id);
          setSubscribeData(response?.data?.data?.subscribe);
          setMemberPay(linkData?.sale_settings?.user_subscribe);
          linkData?.sale_settings?.amounts_default &&
            setDefaultOptions(
              linkData?.sale_settings?.amounts_default
                .split(",")
                ?.map((amount) => amount.trim())
            );
        } else {
          setLoad(false);
        }
      })
      .catch((err) => {
        setLoad(false);
      });
  } catch (err) {
    setLoad(false);
  }
};

const handleGetPdfDetails = async (
  org_id,
  setDocImageUrl,
  setDocTemplate,
  setDocHexCode,
  setDocTermsService,
  setLoadDocs
) => {
  setLoadDocs(true);
  try {
    await axios
      .get(`${getDocSettingsUrl}/${org_id}`)
      .then((response) => {
        setDocImageUrl(response?.data?.data?.doc_image_url);
        setDocTemplate(response?.data?.data?.doc_template || "default");
        setDocHexCode(response?.data?.data?.doc_hex_code || "#2FB5B0");
        setDocTermsService(
          response?.data?.data?.doc_terms_service ||
            "Terms and conditions apply"
        );
      })
      .catch((error) => {});
  } catch (err) {}
  setLoadDocs(false);
};

const getMyImages = async (org_id, setImgUri) => {
  try {
    await axios.get(`${imagesUrl}/logo/${org_id}`).then((response) => {
      setImgUri(`'${response?.data?.data?.bs64data}'`);
    });
  } catch (error) {}
};

const checkName = async (org_id, number, setName, nameOption, setDisabled) => {
  setDisabled(true);
  try {
    await axios
      .get(`${namesUrl}phone=${number}&org=${org_id}`)
      .then((response) =>
        setName(
          response.data && Object.keys(response.data.data).length !== 0
            ? response?.data?.data?.contact_name
            : !nameOption
            ? "anonymous"
            : ""
        )
      )
      .catch((error) => {});
  } catch (err) {}
  setDisabled(false);
};

const checkNameSurvey = async (
  org_id,
  number,
  setName,
  setDisabled,
  setContactId
) => {
  setContactId("");
  setName("");
  setDisabled(true);
  try {
    await axios
      .get(`${namesUrl}phone=${number}&org=${org_id}`)
      .then((response) => {
        if (response.data && Object.keys(response.data.data).length !== 0) {
          setName(response?.data?.data?.contact_name || "");
          setContactId(response?.data?.data?.id || "");
        }
      })
      .catch((error) => {});
  } catch (err) {}
  setDisabled(false);
};

const checkPledgeName = async (
  org_id,
  pledgeNumber,
  setCustomerName,
  setDisabled
) => {
  setDisabled(true);
  try {
    await axios
      .get(`${namesUrl}phone=${pledgeNumber}&org=${org_id}`)
      .then((response) =>
        setCustomerName(
          response.data && Object.keys(response.data.data).length !== 0
            ? response?.data?.data?.contact_name
            : ""
        )
      )
      .catch((error) => {});
  } catch (err) {}
  setDisabled(false);
};

const handlePay = async (
  org_id,
  sales_code,
  amount,
  number,
  paymentData,
  name,
  recurring,
  selection,
  type,
  timer,
  setTrigger,
  toast,
  onClose,
  handleRecheck,
  payType,
  setCheckoutUrl,
  verify,
  setVerify,
  saleId,
  other_details,
  selection_details
) => {
  setTrigger(true);
  let paylink_id;
  let pay_type;
  let multi_names = false;

  if (payType === "card") {
    const cardPayment = paymentData?.find(
      (payment) => payment?.link_type === "CARD"
    );
    if (cardPayment) {
      paylink_id = cardPayment?.link_id;
      pay_type = cardPayment?.pay_type;
    }
  }

  if (
    other_details?.length > 0 ||
    (selection_details && selection_details?.length > 1)
  ) {
    let transaction_time = new Date().getTime();
    multi_names = true;

    // Determine if selection_details is present
    const isSelectionDetailsPresent = selection_details?.length > 0;

    // Calculate total amount from the selected details
    let totalAmount;
    let payDetails;
    let updatedOtherDetails;

    if (isSelectionDetailsPresent) {
      totalAmount = selection_details.reduce(
        (sum, detail) => sum + parseFloat(detail.amount || 0),
        0
      );
      payDetails = selection_details.map((detail) => ({
        name: detail.name,
        amount: parseFloat(detail.amount) || 0,
      }));
      updatedOtherDetails = [{ name: name, amount: amount }];
    } else {
      totalAmount = other_details.reduce(
        (sum, detail) => sum + parseFloat(detail.amount || 0),
        0
      );
      let remainingAmount = amount - totalAmount;
      let adjustedAmount = Math.max(0, remainingAmount);
      payDetails = [{ name: selection, amount: amount }];
      updatedOtherDetails = [
        ...other_details,
        { name: name, amount: adjustedAmount },
      ];
    }

    let data = {
      sale_id: saleId,
      amount: amount,
      phone_number: number.replace("+", ""),
      customer_name: name,
      transaction_time,
      pay_details: payDetails,
      other_details: updatedOtherDetails,
    };

    // console.log(data);
    handleMultinames(data);
  }

  if (!paylink_id) {
    const defaultPayment = paymentData?.find(
      (payment) => payment?.link_type !== "CARD"
    );
    if (defaultPayment) {
      paylink_id = defaultPayment?.link_id;
      pay_type = defaultPayment?.pay_type;
    }
  }

  payType === "card" ? setVerify(true) : setVerify(false);

  if (paylink_id) {
    let data = JSON.stringify({
      org_id: org_id,
      sale_trx_code: sales_code,
      pay_amount: amount.toString(),
      pay_number: number.replace("+", ""),
      paylink_id: paylink_id,
      pay_name: name || "Anonymous",
      pay_details:
        selection_details?.length === 1
          ? selection_details[0].name
          : selection_details?.length > 1
          ? selection_details.map((detail) => detail.name).join(", ")
          : selection || sales_code,
      link_source: "web",
      subscribe_recuring: recurring,
      pay_type: pay_type,
      multi_names,
    });
    try {
      const response = await axios.post(
        payType === "card" ? payCardUrl : payUrl,
        data,
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      if (response?.data?.status === 201) {
        toast.success(response?.data?.message);
        if (payType === "card") {
          if (response?.data?.data?.data?.authorization_url) {
            setCheckoutUrl(response?.data?.data?.data?.authorization_url);
          } else {
            toast.warning("an error occurred! please try again");
            setTrigger(false);
            return;
          }
        }
      } else {
        // console.log(response?.data?.message);
        toast.warning("an error occurred! please check your details");
        setTrigger(false);
        return;
      }
    } catch (err) {
      // console.log(err);
      toast.warning("an error occurred!");
      setTrigger(false);
      return;
    }

    setTimeout(() => {
      toast.info(
        payType === "card"
          ? "Processing checkout..."
          : "Kindly check your phone and pay"
      );
      onClose();
    }, 1000);

    if (type !== "Collection") {
      setTimeout(() => {
        window.location.reload();
      }, Number(timer) + 500);
    }

    payType !== "card" && handleRecheck();
  } else {
    toast.warning("Payment method not yet activated!");
    setTrigger(false);
    return;
  }
};

const handlePaySub = async (
  sales_code,
  subAmount,
  number,
  setTriggerSub,
  subscribeData,
  toast,
  setMemberSub,
  org_id,
  setIsSubLoading,
  setCheckSubscription
) => {
  setTriggerSub(true);

  if (subscribeData?.code && subscribeData?.link_id && subscribeData?.org) {
    let data = JSON.stringify({
      org_id: subscribeData?.org,
      sale_trx_code: subscribeData?.code,
      pay_amount: subAmount.toString(),
      pay_number: number.replace("+", ""),
      paylink_id: subscribeData?.link_id,
      pay_name: "Anonymous",
      pay_details: sales_code,
      link_source: "web",
      subscribe_recuring: false,
      pay_type: "MPESA",
      multi_names: false,
    });

    try {
      const response = await axios.post(payUrl, data, {
        headers: { "Content-Type": "application/json" },
      });

      if (response?.data?.status === 201) {
        toast.success(response?.data?.message);
      } else {
        // console.log(response?.data?.message);
        toast.warning("an error occurred! please try again!");
        setTriggerSub(false);
        return;
      }
    } catch (err) {
      // console.log(err);
      toast.warning("an error occurred!");
      setTriggerSub(false);
      return;
    }

    setTimeout(() => {
      toast.info("Kindly check your phone and pay");
    }, 1000);

    setTimeout(async () => {
      // window.location.reload();
      let checkSubs = await checkSub(
        number,
        setMemberSub,
        org_id,
        setIsSubLoading
      );
      // console.log(checkSubs, "teete");
      !checkSubs &&
        (await checkSub(number, setMemberSub, org_id, setIsSubLoading));
      setCheckSubscription(checkSubs);
      checkSubs && toast.info("Subscription successfully processed!");

      !checkSubs &&
        toast.error(
          "Subscription could not be processed, please try again after the page reloads!"
        );
      !checkSubs &&
        setTimeout(() => {
          window.location.reload();
        }, 6000);
    }, 20000);
  }
};

const handleMultinames = (data) => {
  try {
    axios.post(multinamesUrl, data);
  } catch (err) {}
};

const handleRecheck = async (
  org_id,
  number,
  setPaymentStatus,
  setPaidBy,
  setPhoneNumberBy,
  setTransactionCode,
  setAmount,
  amount,
  setIsToOpen,
  setIsUpdating,
  setVerify
) => {
  setIsUpdating(true);
  const params = {
    phone: number?.replace(/^0/, "254"),
    org: org_id,
  };
  const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay));
  await sleep(Number(timer) + 5000);
  try {
    const response = await axios.get(confirmPaymentUrl, { params: params });
    if (response?.data?.data?.paid_by) {
      setPaymentStatus(true);
      setPaidBy(response?.data?.data?.paid_by || "");
      setPhoneNumberBy(response?.data?.data?.phone_number || "");
      setTransactionCode(response?.data?.data?.trxn_code || "");
      setAmount(response?.data?.data?.amount_paid || amount);
    } else {
      setPaymentStatus(false);
    }
    setVerify(false);
    setIsToOpen(true);
    setIsUpdating(false);
  } catch (error) {
    setIsUpdating(false);
    setPaymentStatus(false);
  }
};

const handlePledge = async (
  org_id,
  sales_code,
  pledgeNumber,
  customerName,
  pledgeAmount,
  selection,
  toast,
  setTrigger,
  timer
) => {
  setTrigger(true);
  let data = JSON.stringify({
    org_id: org_id,
    sale_trx_code: sales_code,
    phone_number: pledgeNumber,
    customer_name: customerName,
    amount: pledgeAmount,
    payment_source: "CREDIT",
    pay_details: selection,
  });

  try {
    const response = await axios.post(pledgeUrl, data, {
      headers: { "Content-Type": "application/json" },
    });

    if (response?.data?.success) {
      toast.success("Thank you for your pledge!");
    } else {
      // console.log(response?.data?.message);
      toast.warning("an error occurred! please check your details");
      setTrigger(false);
      return;
    }
  } catch (err) {
    toast.warning("an error occurred!");
    setTrigger(false);
    return;
  }

  setTimeout(() => {
    window.location.reload();
  }, 200);
};

const handleGeneratePdf = async (
  docTemplate,
  dataSale,
  docTermsService,
  docHexCode,
  docImageUrl,
  downloadPdf,
  setClicked,
  data,
  paymentData,
  original_date,
  toast
) => {
  setClicked(true);
  let dataLoad = {
    doc_type: docTemplate,
    sale_trx_code: dataSale.sale_trx_code,
    sale_amount: dataSale.sale_amount,
    sale_balance: dataSale.sale_balance,
    sale_type: dataSale.sale_type?.toLowerCase(),
    tax: dataSale.sale_tax || 0.0,
    discount: dataSale.sale_discount || 0.0,
    client_number: dataSale.contact_detail,
    client_name: dataSale.contact_name,
    tag_line: docTermsService,
    business_name: data?.data.saledata.org_data.name || "My Business",
    business_location: dataSale.org_data.location,
    business_email: null,
    business_number: dataSale.org_data.main_phone_number,
    hex_color: docHexCode,
    business_category: dataSale.org_data.description,
    business_logo: docImageUrl,
    sale_status: dataSale.sale_status,
    sales_date: original_date,
    due_date: original_date,
    nobuk_logo: "https://www.nobuk.africa/assets/img/nobuklogo1.png",
    payment_details: [
      paymentData?.pay_detail_2
        ? {
            type: paymentData?.pay_type_main,
            number: paymentData?.pay_detail_1,
            account: paymentData.pay_detail_2,
          }
        : {
            type: paymentData?.pay_type_main,
            number: paymentData?.pay_detail_1,
          },
    ],
    items_data: dataSale.items_data,
  };

  try {
    const response = await axios.post(docUrl, dataLoad);
    toast.info("Download started!");
    const base64String = response?.data?.data?.base64pdf;
    const fileName = `${dataSale.sale_trx_code?.toLowerCase()}`;
    downloadPdf(base64String, fileName);
  } catch (err) {
    toast.warning("Download Failed!");
  }
  setClicked(false);
};

const handleGenerateStatements = async ({
  details,
  number,
  paid_by,
  toast,
  downloadPdf,
  setProcessing,
}) => {
  setProcessing(true);
  try {
    const dataLoad = {
      customer_name: paid_by,
      sale_id: details?.id,
    };

    const response = await axios.post(contributorsStatementsUrl, dataLoad);
    if (response?.data?.success) {
      const paymentsData = response?.data?.data || [];

      if (!Array.isArray(paymentsData) || paymentsData.length === 0) {
        toast.info("No data found");
        setProcessing(false);
        return;
      }

      const totalPaid = paymentsData.reduce(
        (acc, payment) => acc + payment.payment_amount,
        0
      );

      const capitalizeFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
      };

      const formattedPaymentsData = paymentsData?.map((payment) => ({
        contact: payment.customer_phone,
        category: payment.account,
        amount: payment.payment_amount,
        payment_mode: payment.item_paid_by,
        date: formatPayDate(payment.payment_time),
      }));

      const combinedData = {
        collection_name: details?.contact_name,
        collection_owner: details?.org_data?.name,
        collection_code: details?.sale_trx_code,
        end_date: timeDate(details?.sales_date),
        contributor_name: paid_by,
        contributor_number: number,
        total_paid: totalPaid,
        collection_type: capitalizeFirstLetter(
          details?.sale_settings?.template_type
        ),
        tag_line: details?.tag_line,
        payments_data: formattedPaymentsData,
      };

      const pdfResponse = await axios.post(statementsUrl, combinedData);

      if (pdfResponse?.data?.data?.base64pdf) {
        toast.success("Download started!");
        const base64String = pdfResponse?.data?.data.base64pdf;
        const fileName = `${details?.sale_trx_code?.toLowerCase()}`;
        downloadPdf(base64String, fileName);
      }
    } else {
      toast.error("Not yet ready, please try again in 15 seconds");
    }
  } catch (err) {
    toast.warning("Download Failed!");
  }
  setProcessing(false);
};

const getSurveyQuestions = async ({ group_id, setSurveyQuestions }) => {
  try {
    await axios.get(`${surveysUrl}/${group_id}`).then((response) => {
      // console.log(response?.data);
      setSurveyQuestions(response?.data);
    });
  } catch (error) {
    // console.log(error.message);
  }
};

const handleSurveySubmit = async ({
  group_id,
  survey_id,
  contact_exists,
  contact_id,
  contact_name,
  response_data,
  toast,
  setTriggerSurvey,
}) => {
  setTriggerSurvey(true);
  let data = {
    group_id: group_id,
    contact_exists: contact_exists,
    survey_id: survey_id,
    contact_id: contact_id,
    contact_name: contact_name,
    response_data: response_data,
  };
  // console.log(data);
  try {
    const response = await axios.post(surveysSubmitUrl, data);

    if (response?.data?.success) {
      console.log(response.data);
      toast.success("Thank you for your time!");
    } else {
      console.log(response?.data?.message);
      toast.warning("an error occurred! please retry");
      setTriggerSurvey(false);
      return;
    }
  } catch (err) {
    toast.warning("an error occurred!");
    setTriggerSurvey(false);
    return;
  }

  setTimeout(() => {
    window.location.reload();
  }, 1000);
};

export {
  handleAsync,
  handleGetPdfDetails,
  getMyImages,
  checkName,
  checkPledge,
  checkPledgeName,
  handlePay,
  handleRecheck,
  handlePledge,
  handleGeneratePdf,
  getContributorsList,
  handleGenerateStatements,
  checkSub,
  handlePaySub,
  getSurveyQuestions,
  handleSurveySubmit,
  checkNameSurvey,
};
